import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, CircularProgress, Typography, TextField, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { HiPhoneMissedCall } from "react-icons/hi";
import { MdPhoneMissed } from "react-icons/md";
import { ImCheckmark } from "react-icons/im";
import { MdAutorenew } from "react-icons/md";
import { BsFilePdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";


// Libraries for data export
import * as XLSX from 'xlsx';
import { Document, Page, StyleSheet, Text, View, PDFDownloadLink } from '@react-pdf/renderer'; // Import from react-pdf


const GetCallsForOrganisation = ({ organisationId }) => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const callsPerPage = 10; // Number of calls per page
  
    // State for filters
    const [statusFilter, setStatusFilter] = useState('');
    const [minDuration, setMinDuration] = useState('');
    const [maxDuration, setMaxDuration] = useState('');
    const [kioskFilter, setKioskFilter] = useState('');
    const [userFilter, setUserFilter] = useState('');
    const [renew, setRenew] = useState(false)
    const [dateFilter, setDateFilter] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  

  const handleDownloadExcel = () => {
    // Prepare data for the Excel sheet
    const dataToExport = filteredCalls.map(call => ({
      'Status': call.cancelledByUser ? 'Geannuleerd door gast' :
                call.cancelledByAgent ? 'Gemiste oproep' : 'Afgerond',
      'Tijdsduur (mm:ss)': formatDuration(call.duration),
      'Datum': new Date(call.createdAt).toLocaleString(),
      'Van kiosk': call.kiosk_id?.name || '',
      'Naar Receptionist': call.user_id?.email || '',
    }));
  
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Calls Data');
  
    // Trigger a download
    XLSX.writeFile(wb, 'calls_data.xlsx');
  };

  const generatePDF = () => {
    // Define the number of rows per page
    const rowsPerPage = 20; // Adjust this based on your content size and layout
    const totalPages = Math.ceil(filteredCalls.length / rowsPerPage);
  
    // Create an array of pages with their respective data chunks
    const pages = Array.from({ length: totalPages }, (_, pageIndex) => {
      const startRow = pageIndex * rowsPerPage;
      const endRow = startRow + rowsPerPage;
      return filteredCalls.slice(startRow, endRow);
    });
  
    return (
      <Document>
        {pages.map((pageData, pageIndex) => (
          <Page key={pageIndex} style={styles.page}>
            {/* Header with dynamic startDate, endDate, and page number */}
            <View style={styles.headerRow}>
              <View style={styles.headerFlex}>
                <Text style={styles.header}>
                  Oproepsdata:
                </Text>
                <Text style={styles.filterHeader}>{!statusFilter && !minDuration && !maxDuration && !kioskFilter && !userFilter && !dateFilter && !startDate && !endDate ? 'Alle data - Geen filters' : 'Toegepaste filters:'}</Text>
                <Text style={styles.filter}>{statusFilter ? `status: ${statusFilter}` : ''}</Text>
                <Text style={styles.filter}>{minDuration ? `minimale duurtijd: ${minDuration}s` : ''}</Text>
                <Text style={styles.filter}>{maxDuration ? `maximale duurtijd: ${maxDuration}s` : ''}</Text>
                <Text style={styles.filter}>{kioskFilter ? `kiosk: ${kioskFilter}` : ''}</Text>
                <Text style={styles.filter}>{userFilter ? `receptionist: ${userFilter}` : ''}</Text>
                <Text style={styles.filter}>{dateFilter ? `datum: ${dateFilter}` : ''}</Text>
                <Text style={styles.filter}>{startDate ? `startDatum:${startDate}` : ''}</Text>
                <Text style={styles.filter}>{endDate ? `eindDatum: ${endDate}` : ''}</Text>
              </View>
              <Text style={styles.pageNumber}>
                Pagina {pageIndex + 1} / {totalPages}
              </Text>
            </View>
  
            {/* Table Header (repeat on every page) */}
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Status</Text>
                <Text style={styles.tableHeader}>Tijdsduur (mm:ss)</Text>
                <Text style={styles.tableHeader}>Datum</Text>
                <Text style={styles.tableHeader}>Van kiosk</Text>
                <Text style={styles.tableHeader}>Naar Receptionist</Text>
              </View>
  
              {/* Table Content */}
              {pageData.map((call, index) => {
                
                const rowIndex = pageIndex * rowsPerPage + index; // Calculate global row index
  
                return (
                  <View
                    style={{
                      ...styles.tableRow,
                      backgroundColor: rowIndex % 2 === 0 ? '#f2f2f2' : 'white', // Alternating background color
                    }}
                    key={index}
                  >
                    <Text style={styles.tableCell}>
                      {call.cancelledByUser
                        ? 'Geannuleerd door gast'
                        : call.cancelledByAgent
                        ? 'Gemiste oproep'
                        : 'Afgerond'}
                    </Text>
                    <Text style={styles.tableCell}>{formatDuration(call.duration)}</Text>
                    <Text style={styles.tableCell}>{new Date(call.createdAt).toLocaleString()}</Text>
                    <Text style={styles.tableCell}>{call.kiosk_id?.name || ''}</Text>
                    <Text style={styles.tableCell}>{call.user_id?.email || ''}</Text>
                  </View>
                );
              })}
            </View>
          </Page>
        ))}
      </Document>
    );
  };
  
  const styles = StyleSheet.create({
    page: { padding: 10, paddingVertical: 30 },
    headerRow: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
    headerFlex: { flexDirection: 'column', gap: 8 },
    header: { fontSize: 14, fontWeight: 'bold' },
    pageNumber: { fontSize: 10, fontStyle: 'italic' },
    table: { width: '100%', marginBottom: 10 },
    tableRow: { flexDirection: 'row', marginBottom: 5, justifyContent: 'space-evenly' },
    filterHeader: { fontSize: 10, marginTop: 10, fontWeight: 'semibold' },
    filter: { fontSize: 9 },
    tableHeader: { 
      width: '20%', 
      fontWeight: 'bold', 
      fontSize: 14, 
      alignSelf: 'center', 
      marginTop: 20, 
      marginBottom: 10,
      backgroundColor: '#024e64',
      color: 'white',
      paddingVertical: 5, 
      textAlign: 'center'
    },
    tableCell: { width: '10%', fontSize: 10 }
  });
  
  useEffect(() => {
    const fetchCalls = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/calls/organisation/${organisationId}`);
        const sortedCalls = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by createdAt descending
        setCalls(sortedCalls);
      } catch (err) {
        setError('Error fetching calls');
      } finally {
        setLoading(false);
      }
    };

    if (organisationId) {
      fetchCalls();
    }

    setPage(0)
  }, [organisationId, renew]);

    // Function to format seconds as mm:ss
    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;  // Pad seconds with leading 0
    };
    

  // Function to handle status filter
  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Function to handle duration filters
  const handleMinDurationChange = (event) => {
    setMinDuration(event.target.value);
  };

  const handleMaxDurationChange = (event) => {
    setMaxDuration(event.target.value);
  };

  // Function to handle kiosk filter
  const handleKioskChange = (event) => {
    setKioskFilter(event.target.value);
  };

  // Function to handle user filter
  const handleUserChange = (event) => {
    setUserFilter(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
    if (event.target.value !== 'custom') {
      setStartDate(null);
      setEndDate(null);
    }
  };

  // Function to filter calls based on selected filters
  const filterCalls = () => {
    return calls.filter((call) => {
      // Filter by status
      if (statusFilter === "cancelledByUser" && !call.cancelledByUser) {
        return false;
      }
      if (statusFilter === "cancelledByAgent" && !call.cancelledByAgent) {
        return false;
      }
      if (statusFilter === "completed" && (call.cancelledByUser || call.cancelledByAgent)) {
        return false;
      }
  
      // Filter by duration
      if (minDuration && call.duration < minDuration) {
        return false;
      }
      if (maxDuration && call.duration > maxDuration) {
        return false;
      }
  
      // Filter by kiosk name
      if (kioskFilter && !call.kiosk_id?.name.toLowerCase().includes(kioskFilter.toLowerCase())) {
        return false;
      }
  
      // Filter by user email
      if (userFilter && !call.user_id?.email.toLowerCase().includes(userFilter.toLowerCase())) {
        return false;
      }
  
      // Date filtering
      let isDateValid = true;
      if (dateFilter) {
        const callDate = new Date(call.createdAt);
  
        // Today filter
        if (dateFilter === 'today') {
          const todayStart = new Date();
          todayStart.setHours(0, 0, 0, 0); // Set to midnight
          isDateValid = callDate >= todayStart;
        }
  
        // Last week filter
        if (dateFilter === 'lastWeek') {
          const lastWeekStart = new Date();
          lastWeekStart.setDate(lastWeekStart.getDate() - 7); // 7 days ago
          isDateValid = callDate >= lastWeekStart;
        }
  
        // Last month filter
        if (dateFilter === 'lastMonth') {
          const lastMonthStart = new Date();
          lastMonthStart.setMonth(lastMonthStart.getMonth() - 1); // 1 month ago
          isDateValid = callDate >= lastMonthStart;
        }
  
        // Custom date filter
        if (dateFilter === 'custom' && startDate && endDate) {
          isDateValid = callDate >= new Date(startDate) && callDate <= new Date(endDate);
        }
      }
  
      return isDateValid;
    });
  };  

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <Typography variant="h6">Loading Calls...</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  const filteredCalls = filterCalls();

  // Calculate the start and end index for the current page
  const startIndex = page * callsPerPage;
  const endIndex = startIndex + callsPerPage;
  const callsToDisplay = filteredCalls.slice(startIndex, endIndex);

  // Function to go to next page
  const handleNextPage = () => {
    if (page * callsPerPage < filteredCalls.length) {
      setPage(page + 1);
    }
  };

  // Function to go to previous page
  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Box sx={{
        width: '100%',
        backgroundColor: '#024e64', 
        color: 'white', 
        padding: '10px 0',
        paddingLeft: '10px',
        marginBottom: '20px', 
        borderRadius: '4px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Typography
            sx={{ marginLeft: '1rem'}}
        >
          Oproepen
        </Typography>
        <Typography
            sx={{ cursor: 'pointer', color: 'white', marginRight: '1rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }} 
        >
          <p 
          style={{
            height: '1.5rem',
            backgroundColor: '#e6f2f6', // match background color
            marginRight: '10px', // match margin to button
            padding: '6px 16px', // same padding as the MUI button
            textDecoration: 'none', // no text decoration
            borderRadius: '4px', // round corners like the MUI button
            color: '#024e64', // white text, like the button
            display: 'inline-flex', // use inline-flex for button-like behavior
            justifyContent: 'center', // center align content
            alignItems: 'center', // vertically center content
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // MUI font family
            fontWeight: '500', // font weight like MUI button
            fontSize: '0.75rem', // font size to match MUI button
            lineHeight: '1.75', // line height similar to button
            letterSpacing: '0.02857em', // match letter spacing
            textTransform: 'uppercase', // text transformation like MUI button
            minWidth: '64px', // minimum width similar to MUI button
            cursor: 'pointer', // show cursor on hover
          }}
          onClick={() => setRenew(!renew)} >
            <MdAutorenew size={18} style={{ marginRight: '1rem', color: '#024e64'}} color='white'/>
            Vernieuwen
            </p>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#4caf50', marginRight: '10px', fontSize: '0.75rem', height: '2.2rem', boxShadow: 'none' }}
              onClick={handleDownloadExcel}
            >
              <View style={{ display: 'flex', gap: '1rem', alignItems: 'center'}}>
                <RiFileExcel2Line size={20} />
                Download als Excel
                </View>
            </Button>
            <PDFDownloadLink document={generatePDF()} fileName="calls_data.pdf"
                style={{
                  height: '1.5rem',
                  backgroundColor: '#1976d2', // match background color
                  marginRight: '10px', // match margin to button
                  padding: '6px 16px', // same padding as the MUI button
                  textDecoration: 'none', // no text decoration
                  borderRadius: '4px', // round corners like the MUI button
                  color: '#fff', // white text, like the button
                  display: 'inline-flex', // use inline-flex for button-like behavior
                  justifyContent: 'center', // center align content
                  alignItems: 'center', // vertically center content
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // MUI font family
                  fontWeight: '500', // font weight like MUI button
                  fontSize: '0.75rem', // font size to match MUI button
                  lineHeight: '1.75', // line height similar to button
                  letterSpacing: '0.02857em', // match letter spacing
                  textTransform: 'uppercase', // text transformation like MUI button
                  minWidth: '64px', // minimum width similar to MUI button
                  cursor: 'pointer', // show cursor on hover
                }}
            >
              {({ loading }) => (loading ? 'Preparing PDF...' : 
                <View style={{ display: 'flex', gap: '1rem', alignItems: 'center'}}>
                  <BsFilePdf size={18} />
                  <p >
                    Download als PDF
                  </p>
                </View>
              )}
            </PDFDownloadLink>
        </Typography>
      </Box>
      {/* Filter inputs above the table */}
      <div style={{ marginBottom: '20px', display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}>
        <FormControl style={{ marginRight: '10px', minWidth: '120px' }}>
          <InputLabel>Status</InputLabel>
          <Select value={statusFilter} onChange={handleStatusChange}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="cancelledByUser">Geannuleerd door gast</MenuItem>
            <MenuItem value="cancelledByAgent">Gemiste oproep</MenuItem>
            <MenuItem value="completed">Afgerond</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginRight: '10px', minWidth: '120px' }}>
            <InputLabel>Datum</InputLabel>
            <Select value={dateFilter} onChange={handleDateFilterChange}>
                <MenuItem value="">Alles</MenuItem>
                <MenuItem value="today">Vandaag</MenuItem>
                <MenuItem value="lastWeek">Vorige week</MenuItem>
                <MenuItem value="lastMonth">Vorige maand</MenuItem>
                <MenuItem value="custom">Aangepast Bereik</MenuItem>
            </Select>
        </FormControl>

{dateFilter === 'custom' && (
  <>
    <TextField
      label="van"
      type="date"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ marginRight: '10px' }}
    />
    <TextField
      label="tot"
      type="date"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ marginRight: '10px' }}
    />
  </>
)}

        <TextField
          label="Minimale duur (s)"
          type="number"
          value={minDuration}
          onChange={handleMinDurationChange}
          style={{ marginRight: '10px' }}
        />

        <TextField
          label="Maximale duur (s)"
          type="number"
          value={maxDuration}
          onChange={handleMaxDurationChange}
          style={{ marginRight: '10px' }}
        />

        <TextField
          label="Kiosk"
          value={kioskFilter}
          onChange={handleKioskChange}
          style={{ marginRight: '10px' }}
        />

        <TextField
          label="Email Receptionist"
          value={userFilter}
          onChange={handleUserChange}
          style={{ marginRight: '10px' }}
        />

        <Button variant="contained" sx={{ backgroundColor: '#f79251'}} onClick={() => { setStatusFilter(''); setMinDuration(''); setMaxDuration(''); setKioskFilter(''); setUserFilter(''); }}>
          Reset Filters
        </Button>
      </div>

      {/* Table to display filtered calls */}
      <TableContainer component={Paper} sx={{ backgroundColor: '#e6f2f6', width: '100%'}}>
        <Table>
          <TableHead sx={{ backgroundColor: '#024e64'}}>
            <TableRow>
              <TableCell sx={{ color: 'white'}}>Status</TableCell>
              <TableCell sx={{ color: 'white'}}>Tijdsduur (mm:ss)</TableCell>
              <TableCell sx={{ color: 'white'}}>Datum:</TableCell>
              <TableCell sx={{ color: 'white'}}>Van kiosk:</TableCell>
              <TableCell sx={{ color: 'white'}}>Naar Receptionist:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {callsToDisplay.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  Geen oproepen gevonden
                </TableCell>
              </TableRow>
            ) : (
              callsToDisplay.map((call) => (
                <TableRow key={call._id}>
                  <TableCell>{call.cancelledByUser ? <div style={{ display: 'flex', alignItems:'center', gap:'0.5rem'}}><HiPhoneMissedCall color='orange'/><p style={{ color: 'orange'}}>Geannuleerd door gast</p></div> : call.cancelledByAgent ? <div style={{ display: 'flex', alignItems:'center', gap:'0.5rem'}}><MdPhoneMissed color='red'/><p style={{ color: 'red' }}>Gemiste oproep</p></div> : <div style={{ display: 'flex', alignItems:'center', gap:'0.5rem'}}><ImCheckmark color='green' /><p style={{ color: 'green'}}>Afgerond</p></div>}</TableCell>
                  <TableCell><p>{formatDuration(call.duration)}</p></TableCell>
                  <TableCell><p>{new Date(call.createdAt).toLocaleString()}</p></TableCell>
                  <TableCell><p>{call.kiosk_id?.name}</p></TableCell>
                  <TableCell><p>{call.user_id.email}</p></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{width: '100%', height: '1rem', display: 'flex', justifyContent:'flex-end' }}>
            <Typography sx={{ width: 'auto', color: 'white', backgroundColor: '#024e64', padding: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }}>
                {page*callsPerPage !== 0 ? page*callsPerPage+1 : page*callsPerPage} - {callsPerPage*page+callsPerPage < filteredCalls.length ? callsPerPage*page+callsPerPage : filteredCalls.length} / {filteredCalls.length}
            </Typography>
        </Box>
        {/* Pagination controls */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '4rem' }}>
        <Button
          variant="contained"
          onClick={handlePreviousPage}
          disabled={page === 0}
          sx={{ marginRight: '10px', backgroundColor: '#024e64' }}
        >
          Vorige
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#024e64'}}
          color="primary"
          onClick={handleNextPage}
          disabled={(page + 1) * callsPerPage >= filteredCalls.length}
        >
          Volgende
        </Button>
      </Box>
    </>
  );
};

export default GetCallsForOrganisation;