import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSocket } from '../socket.context'; // Import your socket context hook
import CordaImage from '../media/images/corda.png';
import MemoImage from '../media/images/memo.svg';
import './Home.css';

const Home = () => {
  const { identity, roomname } = useParams(); // Extract identity and roomname from the URL
  const [error, setError] = useState('');
  const [waitingForReceptionist, setWaitingForReceptionist] = useState(false); // State for waiting status
  const [kioskData, setKioskData] = useState(null); // State to store the kiosk data
  const [loading, setLoading] = useState(true); // State for loading
  const navigate = useNavigate(); // Hook to navigate to another page after token retrieval
  const { socket, connected } = useSocket(); // Get socket context

  // Fetch the kiosk data when the page loads or the URL params change
  useEffect(() => {
    sessionStorage.removeItem('isActive');
    sessionStorage.removeItem('jwt_token');
    const fetchKioskData = async () => {
      if (!identity || !roomname) {
        setError('Identity or roomname parameter is missing in the URL.');
        return;
      }

      try {
        // Fetch kiosks filtered by organisation_id (identity)
        const response = await axios.get('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/kiosks', {
          params: { organisation_id: identity } // Send the organisation_id as a query parameter
        });

        // Check if the kiosk exists based on identity (organisation_id) and roomname (kiosk_id)
        const kiosk = response.data.find(
          (kiosk) => kiosk._id === roomname && kiosk.organisation_id._id === identity
        );

        if (kiosk) {
          setKioskData(kiosk); // Update the state with the found kiosk data
          setError('');  // Reset the error state
          localStorage.removeItem('kioskId');
          localStorage.removeItem('organisationId');
          localStorage.setItem('kioskId', roomname);
          localStorage.setItem('organisationId', identity);
        } else {
          setError('The specified kiosk or organisation does not exist.');
          navigate('/404'); // Redirect to a 404 page or error page if the kiosk does not exist
        }
      } catch (err) {
        console.error('Error fetching kiosk data:', err);
        setError('Failed to fetch kiosk data. Please try again.');
      }
      setLoading(false); // Stop loading after the data fetch completes
    };

    fetchKioskData(); // Call the function to fetch kiosk data on mount
  }, [identity, roomname, navigate]); // This will trigger when identity or roomname changes

  useEffect(() => {
    if (socket && kioskData) {
      let kioskName = kioskData.name;
      let kioskOrgId = kioskData.organisation_id._id;
      socket.emit('kioskLive', { kioskName, kioskOrgId})
    }
  })
  // Function to handle token request when the button is clicked
  const requestToken = async () => {
    // Check if the identity and roomname exist
    if (!identity || !roomname) {
      setError('Identity or roomname parameter is missing in the URL.');
      return;
    }

    try {
      socket.emit('joinRoomRequest', {
        roomName: kioskData.organisation_id.name,
        kioskName: kioskData.name
      }); 
      setWaitingForReceptionist(true); // Set waiting state to true
    } catch {
    }
  };

  // Function to handle cancelling the waiting process
  const cancelCall = () => {
    // Emit event to the server to cancel the call if necessary
    if (socket) {
      socket.emit('cancelCall', { roomName: roomname });
    }
    setWaitingForReceptionist(false); // Reset the waiting state
  };

  useEffect(() => {

    // Listen for room status updates (e.g., no receptionists available)
    if (socket) {
      socket.on('roomStatus', (data) => {
        if (data.message) {
          if (data.message !== 'De receptie wordt gebeld..' && data.message !== 'annulatie') {
            setWaitingForReceptionist(false)
            setError(data.message);
            setTimeout(() => {
              setError('');
            }, 10000);
          } else if (data.message === 'annulatie') {
            setError('')
          } else if (data.message !== 'annulatie') {
            setError(data.message)
          }
        }
      });

      // Listen for the 'callAccepted' event from the server
        socket.on('callAccepted', (data) => {
        setWaitingForReceptionist(false);
        navigate(`/video-room/${identity}/${kioskData.name}`)
      });
    }

    return () => {
      if (socket) {
        socket.off('roomStatus');  // Clean up event listener on component unmount
        socket.off('callAccepted');  // Clean up the 'callAccepted' event listener
      }
    };
  }, [socket, identity, roomname, navigate, waitingForReceptionist, error]);

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>

      {loading ? (  // Check if the loading state is true
        <div className="loader-container">
          <p>Loading...</p> {/* Simple text loader */}
        </div>
      ) : (
        <div>
          {identity && roomname ? (
            <div>
              {/* Display kiosk name and organisation name if available */}
              {kioskData && (
                <div>
                  {
                    kioskData.organisation_id.name === 'Corda' 
                    ? <img className='kiosk_logo' src={CordaImage} alt="Corda" /> 
                    : <img className='kiosk_logo' src={MemoImage} alt="Memo" />
                  }
                </div>
              )}
              {/* Change button text based on waitingForReceptionist state */}
              <button
                onClick={waitingForReceptionist ? cancelCall : requestToken}
                disabled={!connected || !identity || !roomname} // Disable if not connected or missing params
                style={{
                  padding: '10px 20px',
                  fontSize: '20px',
                  cursor: 'pointer',
                  backgroundColor: '#007055',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  marginTop: '10px',
                  position: 'absolute',
                  bottom:'15rem',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              >
                {waitingForReceptionist ? 'Annuleer' : 'Bel de receptie'}
              </button>
            </div>
          ) : (
            <p>Identity and Roomname are missing from the URL.</p>
          )}

          {/* Show any errors */}
          {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}

          {/* Connection status */}
          {!connected && <p style={{ color: 'red', marginTop: '10px' }}>Aan het connecteren...</p>}
        </div>
      )}
    </div>
  );
};

export default Home;
