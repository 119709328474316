import React from 'react';
import { useUser } from '../helpers/UserContext';
import SideBar from './SideBar';
import Header from '../components/Header';
import useLogout from '../hooks/useLogout';
import useCallHandling from '../hooks/useCallHandling';  // Import the useCallHandling hook
import './BaseLayout.css'

const BaseLayout = ({ children }) => {
  const user = useUser();
  const handleLogout = useLogout();
  
  // Call handling logic
  const { incomingCall, callingUserId, callingRoomName, handlePickUp, handleDecline, callingKiosk } = useCallHandling();

  return (
    <div className='main--wrapper'>
      <div style={{ width: '100%', height: '1rem', zIndex:'99', backgroundColor: '#fff'}}></div>
        <div className="main--container">
        {/* Sidebar Component */}
        <SideBar user={user} handleLogout={handleLogout} />
        <div className='main--flex'>
          <div style={{ width: '100%', height: '1rem', zIndex:'99', backgroundColor: '#fff', position: 'fixed', top: '0', left: '0'}}></div>
            <Header 
                incomingCall={incomingCall} 
                onPickUp={handlePickUp} 
                onDecline={handleDecline} 
                handleLogout={handleLogout}
                callingUserData={callingUserId}
                callingRoomName={callingRoomName}
                callingKiosk={callingKiosk}
                user={user}
                />
            {/* Main content area */}
            <div className="main--content">
                {/* Dynamic page content */}
                {children}
            </div>
        </div>
        </div>
    </div>
  );
};

export default BaseLayout;