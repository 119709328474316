import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children, userId }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(true); // Track connection state
  const [ip, setIp] = useState();

  let ipCheckInterval = null;

  const checkIpChange = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const newIp = data.ip;
      console.log('Pinging server..')
      if (ip && ip !== newIp) {
        console.log('Change detected...')
        setConnected(false)
      } else {
        setConnected(true)
      }
      setIp(newIp);
    } catch (error) {
      console.error('Error fetching IP:', error);
      setConnected(false)
    }
  };

  useEffect(() => {
    // Start checking for IP changes when the component mounts
    ipCheckInterval = setInterval(checkIpChange, 4000);

    // Cleanup function to stop checking when the component unmounts
    return () => {
      clearInterval(ipCheckInterval);
    };
  }, [ip, connected]); // Effect depends on `ip` state, but only checks IP every 5 seconds

  useEffect(() => {

    // Check if there is a saved JWT token in localStorage
    const token = sessionStorage.getItem('jwt_token'); // Or sessionStorage or cookies
    const savedSocketId = sessionStorage.getItem('socketId');
    
    // Define the query parameters, including the token if available
    const query = {
      userId,
      socketId: savedSocketId,
      token: token || '', // If no token, send an empty string
    };

    const socketInstance = io('wss://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net', {
      transports: ['websocket'], // Prefer WebSocket transport for better performance
      query, // Send the query with the user data and token
      pingInterval: 3000, // 3 seconds
      pingTimeout: 2000,   // 2 seconds
    });

    setSocket(socketInstance);

    socketInstance.on('connect', async() => {
      
      sessionStorage.setItem('socketId', socketInstance.id); // Store socket ID in sessionStorage
      if (!connected) {
        setConnected(true)
      }
      console.log('connected!!!')
      // Listen for the 'loginSuccess' event
      socketInstance.on('loginSuccess', (data) => {
        if (data.socketId) {
          // Store the client ID in sessionStorage if login is successful
          sessionStorage.setItem('socketId', data.socketId);
        }
      });
    });

    socketInstance.on("reconnection_attempt", () => {
      console.log("test_reconnect")
    })

    socketInstance.on("reconnect", () => {
      console.log("reconnected")
    })

    socketInstance.on('disconnect', () => {
      setConnected(false); // Set connected state to false when socket disconnects
      console.log('disconnected!!!')
    });

    // Cleanup function: disconnect socket and remove listeners when component unmounts
    return () => {
      socketInstance.disconnect();
      setSocket(null); // Reset socket state
      setConnected(false); // Reset connected state
    };
  }, [ip]); // Use userId as a dependency to reconnect if it changes

  return (
    <SocketContext.Provider value={{ socket, connected }}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to access the socket context
export const useSocket = () => {
  return useContext(SocketContext);
};
