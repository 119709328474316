import { useState, useEffect } from 'react';
import { useSocket } from '../socket.context';
import AudioRingTone from '../media/audio/ringtone.mp3';
import axios from 'axios';  // Import axios for the API request
import Video from 'twilio-video';  // Import Twilio Video SDK
import { useNavigate } from 'react-router-dom';
import { useUser } from '../helpers/UserContext';

const useCallHandling = () => {
  const [incomingCall, setIncomingCall] = useState(false);
  const [callingUserId, setCallingUserId] = useState(null);
  const [callingRoomname, setCallingRoomname] = useState(null);
  const [callingKiosk, setCallingKiosk] = useState(null);
  const [user, setUser] = useState(null)
  const [ringtone, setRingtone] = useState(null);  // To store the ringtone audio object
  const { socket } = useSocket();
  const [error, setError] = useState('');  // To store error messages
  const navigate = useNavigate()
  const currentUser = useUser()

  // Handle pick up action
  const handlePickUp = async () => {
  
    // Stop the ringtone
    if (ringtone) {
      ringtone.pause();  // Stop the ringtone
      ringtone.currentTime = 0;  // Reset the ringtone to the start
    }
  
    setIncomingCall(false);  // Hide the call controls after picking up

    // Fetch Twilio token before joining the room
    try {
      const identity = 'receptionist';  // This can be dynamic, based on who the receptionist is
      const roomname = callingKiosk;  // Room name from the incoming call data
  
      // Fetch the Twilio token from the backend
      const tokenResponse = await axios.get(`https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/twilio/video-token/${user}/${roomname}`);
  
      if (tokenResponse.data) {
        localStorage.setItem('twilioToken', tokenResponse.data);  // Save the token to localStorage
        setError('');  // Reset the error state
  
        // Emit event to accept the call and inform the guest
        if (socket && callingUserId) {
          socket.emit('callAccepted', {
            guestSocketId: callingUserId,   // Guest's socket ID
            roomName: callingKiosk,          // Room name for the video call
          });
        }

      } else {
        setError('Failed to retrieve token. Please try again.');
      }
    } catch (err) {
      console.error('Error fetching token:', err);
      setError('Failed to fetch token. Please try again.');
    }
  };

  // Handle decline action
  const handleDecline = () => {
    if (ringtone) {
      ringtone.pause();  // Stop the ringtone
      ringtone.currentTime = 0;  // Reset the ringtone to the start
    }
    setIncomingCall(false);  // Hide the call controls after decline
    if (socket) {
      socket.emit('declineCall', { guestSocketId: callingUserId });
    }
  };

  useEffect(() => {
    if (socket) {
      setUser(currentUser.email)

      socket.on('autoDecline', (data) => {
        handleDecline()
      })

      socket.on('incomingCall', (data) => {

        setCallingUserId(data.guestSocketId);
        setCallingRoomname(data.roomName);
        setCallingKiosk(data.kioskName);
        setIncomingCall(true);

        // Create and play the ringtone
        const newRingtone = new Audio(AudioRingTone);
        setRingtone(newRingtone);  // Save the audio object in state
        newRingtone.play();

      });

      socket.on('callCanceled', (data) => {
        if (ringtone) {
          ringtone.pause();  // Stop the ringtone
          ringtone.currentTime = 0;  // Reset the ringtone to the start
        }
        setIncomingCall(false);  // Hide the call controls after cancel
      });
    }

    return () => {
      if (socket) {
        socket.off('incomingCall');
        socket.off('callCanceled');
      }
    };
  }, [socket, ringtone]);

  return { incomingCall, callingUserId, callingRoomname, callingKiosk, handlePickUp, handleDecline, error };
};

export default useCallHandling;
