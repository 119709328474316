import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography, CircularProgress, Container, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSocket } from '../socket.context'; // Assuming you have a Socket context hook to use the socket
import { jwtDecode } from 'jwt-decode'; // Add JWT decode library

const Login = () => {
  const navigate = useNavigate();
  const { socket } = useSocket(); // Get the socket from your context
  const [error, setError] = React.useState('');

  // Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/auth/login', {
          email: values.email.toLowerCase(),
          password: values.password,
        });

        const { access_token } = response.data;

        // Decode the JWT token to extract the organisation and role
        const decodedToken = jwtDecode(access_token);
        const { organisationName, organisationId, email, role } = decodedToken; // Assuming these are in the token

        // Store the JWT token in sessionStorage
        sessionStorage.setItem('jwt_token', access_token);

        // Emit the 'login' event to store the organisationName and role in socket data
        if (socket) {
          socket.emit('login', { organisationName, role, organisationId, email, access_token });
        }

        // Redirect to dashboard after successful login
        navigate('/');
      } catch (error) {
        console.error('Login failed', error);
        setError('Login failed, please try again!');
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
      <Paper elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Sign In
        </Typography>

        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <TextField
            label="Email Address"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
            color="primary"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
          </Button>
        </form>

        {/* Display error message if login fails */}
        {error && <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>{error}</Typography>}
      </Paper>
    </Container>
  );
};

export default Login;
